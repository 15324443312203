// =============================================================================
// Sizes
// =============================================================================

// Max widths and fixed components heights
$size-maxwidth:         1440px;

$header-height:         71px;
$header-height-small:   35px;

$gutter:                30px;

$border-radius:         100px;

// =============================================================================
// Colors
// =============================================================================

// Brand colors
$color-orange:          #C5732F;
$color-purple:          #AFAFD5;
$color-yellow:          #D5B538;
$color-yellow-dark:     #958746;
$color-yellow-light:    #F4F0D7;

// Neutral colors
$color-white:           #FFFFFF;
$color-gray-dark:       #040506;
$color-black:           #000000;

// Theme colors
$theme-afterhours:      $color-yellow-dark;
$theme-lounge:          $color-orange;
$theme-popup:           $color-yellow;
$theme-emporium:        $color-purple;


// =============================================================================
// Typography
// =============================================================================

@font-face {
  font-family: "Helvetica Neue";
  src: url('../fonts/HelveticaNeueLTPro-Roman.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url('../fonts/HelveticaNeueLTPro-Lt.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url('../fonts/HelveticaNeueLTPro-Bd.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

$font-primary: 'Helvetica Neue', sans-serif;

$font-xsmall:         pxrem(13);
$font-small:          pxrem(15);
$font-normal:         pxrem(16);
$font-medium:         pxrem(18);
$font-discrete:       pxrem(20);
$font-large:          pxrem(25);
$font-xlarge:         pxrem(30);
$font-xxlarge:        pxrem(40);
$font-xxxlarge:       pxrem(50);
$font-huge:           pxrem(80);
$font-special-small:  pxrem(100);
$font-special:        pxrem(130);


// =============================================================================
// Transitions
// =============================================================================

$transition-default: 150ms ease-in-out;