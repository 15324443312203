// Typography styles

@mixin basicTypographyStyle() {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
}

@mixin typo-1 {
  @include basicTypographyStyle;
  font-size: $font-xxlarge;
  line-height: 1;

  @include mq(xs) {
    font-size: $font-xxxlarge;
  }

  @include mq(md) {
    font-size: $font-huge;
  }

  @include mq(lg) {
      font-size: $font-special;
  }
}

@mixin typo-1-small {
  @include basicTypographyStyle;
  font-size: $font-xxlarge;
  line-height: 1;

  @include mq(md) {
    font-size: $font-xxxlarge;
  }

  @include mq(lg) {
      font-size: $font-special-small;
  }
}

@mixin typo-2 {
  @include basicTypographyStyle;
  font-size: $font-xlarge;
  line-height: 1.15;

  @include mq(md) {
    font-size: $font-xxlarge;
  }

  @include mq(lg) {
      font-size: $font-xxxlarge;
  }
}

@mixin typo-2-scale {
  @include basicTypographyStyle;
  font-size: $font-large;
  line-height: 1.15;

  @include mq(xs) {
    font-size: $font-xlarge;
  }

  @include mq(md) {
    font-size: $font-xxlarge;
  }

  @include mq(lg) {
      font-size: $font-xxxlarge;
  }
}

@mixin typo-3 {
  @include basicTypographyStyle;
  font-size: $font-large;
  line-height: 1.1;

  @include mq(lg) {
      font-size: $font-xlarge;
  }
}

@mixin typo-3-scale {
  @include basicTypographyStyle;
  font-size: $font-discrete;
  line-height: 1.1;
  
  @include mq(xs) {
    font-size: $font-large;
  }

  @include mq(lg) {
      font-size: $font-xlarge;
  }
}

@mixin typo-4 {
  @include basicTypographyStyle;
  font-size: $font-medium;
  line-height: 1.1;

  @include mq(lg) {
      font-size: $font-discrete;
  }
}

@mixin typo-4-scale {
  @include basicTypographyStyle;
  font-size: $font-normal;
  line-height: 1.1;

  @include mq(xs) {
    font-size: $font-medium;
  }

  @include mq(lg) {
      font-size: $font-discrete;
  }
}

@mixin typo-4-special {
  @include basicTypographyStyle;
  font-size: $font-small;
  line-height: 1.1;

  @include mq(lg) {
      font-size: $font-discrete;
  }
}