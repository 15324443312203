.theme-link {
  align-items: center;
  border: 1px solid $color-yellow-light;
  border-radius: 45px;
  display: inline-flex;
  padding: pxrem(20) pxrem(30);
  text-decoration: none;

  &:hover {
    background-color: $color-yellow-light;
    color: $color-black;
    opacity: 1;

    // &.theme--afterhours {
    //   color: $theme-afterhours;
    // }
  
    // &.theme--lounge {
    //   color: $theme-lounge;
    // }
  
    // &.theme--popup {
    //   color: $theme-popup;
    // }
  
    // &.theme--emporium {
    //   color: $theme-emporium;
    // }
  }

  svg {
    display: inline-block;
    margin-right: pxrem(15);
  }

  .label {
    @include typo-3;
    display: block;
    margin-top: 10px;
    white-space: nowrap;
  }

}