.place {

  .wrapper {
    @include max-width;
    padding-bottom: pxrem(30);
    padding-top: pxrem(60);

    @include mq(lg) {
      align-items: center;
      display: flex;
    }
  }

  .place-title {
    @include typo-1-small;
    margin-bottom: pxrem(25);

    @include mq(lg) {
      margin-bottom: 0;
      width: 30%;
    }

    @include mq(xxl) {
      width: 20%;
    }
  }

  .place-list {

    @include mq(lg) {
      display: flex;
      flex-wrap: wrap;
      padding-left: pxrem(30);
      width: 70%;
    }

    @include mq(xxl) {
      width: 80%;
    }
  }

  .place-info {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    margin-bottom: pxrem(10);
    margin-top: pxrem(10);
    
    @include mq(md) {
      margin-left: 1.5%;
      margin-right: 1.5%;
      width: calc(50% - 3%);
    }

    @include mq(xxl) {
      margin-left: 1.5%;
      margin-right: 1.5%;
      width: calc(33% - 3%);
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &.place--openings {
    .place-title {
      @include typo-2;
      line-height: 0.8;
    }
  }
}