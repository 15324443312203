.scrolling-bar {
  background-color: $color-yellow-light;
  border-bottom: 1px solid $color-black;
  color: $color-black;
  padding-bottom: pxrem(20);
  padding-top: pxrem(20);

  .scrolling-bar-item {
    align-items: center;
    display: inline-flex;
    margin-left: pxrem(30);

    .label {
      @include typo-4;
      margin-left: pxrem(12);
      margin-top: 5px;
    }
  }
}