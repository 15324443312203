.theme-text {
  display: inline-flex;

  svg {
    display: inline-block;
    margin-left: pxrem(10);
  }

  &.theme--afterhours {
    color: $theme-afterhours;
  }

  &.theme--lounge {
    color: $theme-lounge;
  }

  &.theme--popup {
    color: $theme-popup;
  }

  &.theme--emporium {
    color: $theme-emporium;
  }

}