.social-buttons {
  @include list-reset;
  bottom: 20px;
  right: 10px;
  position: fixed;
  z-index: 1;

  @include mq(md) {
    bottom: 30px;
    right: 60px;
  }

  .menu-item {
    display: inline-block;
    margin-left: pxrem(5);
    margin-right: pxrem(5);

    @include mq(md) {
      margin-left: pxrem(10);
      margin-right: pxrem(10);
    }
  }

  .menu-link {
    align-items: center;
    background-color: $color-purple;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    height: pxrem(58);
    width: pxrem(58);

    @include mq(md) {
      height: pxrem(90);
      width: pxrem(90);
    }

    &:hover {
      background-color: $color-yellow;
      opacity: 1;
    }
  }
}