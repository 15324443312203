.icon {
	display: block;
	flex-shrink: 0;
	pointer-events: none;

	// Icon transformations
	&.icon--rotate-45 {
		transform: rotate(45deg);
	}

	&.icon--rotate-90 {
		transform: rotate(90deg);
	}

	&.icon--rotate-180 {
		transform: rotate(180deg);
	}

	&.icon--rotate-270 {
		transform: rotate(270deg);
	}

	// Custom icons
	&.icon--symbol-small {
		height: pxrem(25);
		width: pxrem(25);
	}

	&.icon--symbol-regular {
		height: pxrem(30);
		width: pxrem(30);

		@include mq(lg) {
			height: pxrem(45);
			width: pxrem(45);
		}
	}

	&.icon--symbol-hero-circle {
		height: pxrem(220);
		width: pxrem(220);

		@include mq(lg) {
			height: pxrem(330);
			width: pxrem(330);
		}
	}

	&.icon--symbol-hero-star {
		height: pxrem(280);
		width: pxrem(270);

		@include mq(lg) {
			height: pxrem(425);
			width: pxrem(405);
		}
	}

	&.icon--symbol-hero-half-circle {
		height: pxrem(220);
		width: pxrem(220);

		@include mq(lg) {
			height: pxrem(330);
			width: pxrem(330);
		}
	}

	&.icon--symbol-hero-four-point {
		height: pxrem(300);
		width: pxrem(300);

		@include mq(lg) {
			height: pxrem(450);
			width: pxrem(450);
		}
	}

	&.icon--logo {
		height: pxrem(126);
		width: pxrem(180);

		@include mq(lg) {
			height: pxrem(200);
			width: pxrem(285);
		}
	}

	&.icon--arrow {
		@include icon-color;
		@include icon-nofill;
		height: pxrem(15);
		stroke-width: 6;
		width: pxrem(15);

		@include mq(lg) {
			height: pxrem(45);
			width: pxrem(45);
		}
	}

	&.icon--arrow-shop {
		@include icon-color;
		@include icon-nofill;
		flex-shrink: 0;
		height: pxrem(15);
		stroke-width: 6;
		width: pxrem(15);

		@include mq(md) {
			height: pxrem(35);
			width: pxrem(35);
		}

		@include mq(xl) {
			height: pxrem(45);
			width: pxrem(45);
		}
	}

	&.icon--social {
		height: pxrem(15);
		width: pxrem(15);

		@include mq(lg) {
			height: pxrem(40);
			width: pxrem(40);
		}
	}
}