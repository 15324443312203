.section--intro {
  border-bottom: 1px solid $color-yellow-light;
  padding-bottom: pxrem(100);
  padding-top: pxrem(100);

  .wrapper {
    @include max-width;
  }

  .text {
    @include typo-2;
  }
}