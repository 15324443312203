.section--shops {
  background-color: $color-yellow-light;
  border-radius: $border-radius;
  margin-bottom: pxrem(50);
  position: relative;

  .wrapper {
    @include max-width;
  }

  .info-wrapper {
    padding-bottom: pxrem(40);
    padding-left: pxrem(20);
    padding-top: pxrem(60);

    @include mq(lg) {
      padding-bottom: pxrem(77);
      padding-top: pxrem(80);
      width: 70%;
    }

    .title {
      @include typo-1-small;
      color: $color-black;
      margin-bottom: pxrem(20);

      @include mq(lg) {
        margin-bottom: pxrem(40);
      }
    }

    .description {
      @include typo-3;
      color: $color-black;
      margin-bottom: pxrem(20);

      @include mq(lg) {
        margin-bottom: 0;
      }
    }
  }

  .shop-wrapper {
    padding-bottom: pxrem(60);

    @include mq(md) {
      column-gap: pxrem(30);
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-bottom: pxrem(100);
      row-gap: pxrem(30);
    }
  }
  
}