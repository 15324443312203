.section--hero {
  background-color: $color-yellow-light;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  height: calc(100vh - #{$header-height});
  overflow: hidden;
  position: relative;

  .wrapper {
    @include max-width;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: pxrem(60);
    padding-top: pxrem(60);
    position: relative;
    z-index: 1;
  }

  .title {
    @include typo-1;
    color: $color-black;
    cursor: pointer;
    margin-bottom: pxrem(20);
    margin-top: auto;
    transition: transform $transition-default;
    user-select: none;

    &:hover {
      transform: translateX(10px) !important;
    }
  }
}