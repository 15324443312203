@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeueLTPro-Roman.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeueLTPro-Lt.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeueLTPro-Bd.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
* {
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: 0;
}

html {
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  width: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: #F4F0D7;
  -webkit-text-size-adjust: 100%;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 1rem;
  height: 100%;
  line-height: 1.2;
  min-height: 100%;
  width: 100%;
}

[unselectable] {
  -webkit-touch-callout: none;
  user-select: none;
}

a {
  color: #F4F0D7;
  opacity: 1;
  transition: all 150ms ease-in-out;
}
a:hover {
  opacity: 0.75;
}
a:visited, a:active {
  color: #F4F0D7;
}

img, media, svg {
  display: block;
  height: auto;
  max-width: 100%;
}

.svg-symbols {
  height: 0;
}

ul, ol {
  margin: 0;
}

/* Chrome autocomplete form fix */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

/* IE10+ specific styles */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    height: auto;
  }
}
/* Safari 9 specific styles */
@supports (overflow: -webkit-marquee) and (justify-content: inherit) {
  html {
    height: auto;
  }
}
.h-hide {
  display: none !important;
  visibility: hidden;
}

.h-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.h-noscroll {
  overflow: hidden;
}

.icon {
  display: block;
  flex-shrink: 0;
  pointer-events: none;
}
.icon.icon--rotate-45 {
  transform: rotate(45deg);
}
.icon.icon--rotate-90 {
  transform: rotate(90deg);
}
.icon.icon--rotate-180 {
  transform: rotate(180deg);
}
.icon.icon--rotate-270 {
  transform: rotate(270deg);
}
.icon.icon--symbol-small {
  height: 1.5625rem;
  width: 1.5625rem;
}
.icon.icon--symbol-regular {
  height: 1.875rem;
  width: 1.875rem;
}
@media (min-width: 64.0625em) {
  .icon.icon--symbol-regular {
    height: 2.8125rem;
    width: 2.8125rem;
  }
}
.icon.icon--symbol-hero-circle {
  height: 13.75rem;
  width: 13.75rem;
}
@media (min-width: 64.0625em) {
  .icon.icon--symbol-hero-circle {
    height: 20.625rem;
    width: 20.625rem;
  }
}
.icon.icon--symbol-hero-star {
  height: 17.5rem;
  width: 16.875rem;
}
@media (min-width: 64.0625em) {
  .icon.icon--symbol-hero-star {
    height: 26.5625rem;
    width: 25.3125rem;
  }
}
.icon.icon--symbol-hero-half-circle {
  height: 13.75rem;
  width: 13.75rem;
}
@media (min-width: 64.0625em) {
  .icon.icon--symbol-hero-half-circle {
    height: 20.625rem;
    width: 20.625rem;
  }
}
.icon.icon--symbol-hero-four-point {
  height: 18.75rem;
  width: 18.75rem;
}
@media (min-width: 64.0625em) {
  .icon.icon--symbol-hero-four-point {
    height: 28.125rem;
    width: 28.125rem;
  }
}
.icon.icon--logo {
  height: 7.875rem;
  width: 11.25rem;
}
@media (min-width: 64.0625em) {
  .icon.icon--logo {
    height: 12.5rem;
    width: 17.8125rem;
  }
}
.icon.icon--arrow {
  fill: #000000;
  stroke: #000000;
  fill: none !important;
  height: 0.9375rem;
  stroke-width: 6;
  width: 0.9375rem;
}
.icon.icon--arrow line,
.icon.icon--arrow circle,
.icon.icon--arrow path,
.icon.icon--arrow polygon,
.icon.icon--arrow rect {
  fill: #000000;
  stroke: #000000;
}
.icon.icon--arrow line,
.icon.icon--arrow circle,
.icon.icon--arrow path,
.icon.icon--arrow polygon,
.icon.icon--arrow rect {
  fill: none !important;
}
@media (min-width: 64.0625em) {
  .icon.icon--arrow {
    height: 2.8125rem;
    width: 2.8125rem;
  }
}
.icon.icon--arrow-shop {
  fill: #000000;
  stroke: #000000;
  fill: none !important;
  flex-shrink: 0;
  height: 0.9375rem;
  stroke-width: 6;
  width: 0.9375rem;
}
.icon.icon--arrow-shop line,
.icon.icon--arrow-shop circle,
.icon.icon--arrow-shop path,
.icon.icon--arrow-shop polygon,
.icon.icon--arrow-shop rect {
  fill: #000000;
  stroke: #000000;
}
.icon.icon--arrow-shop line,
.icon.icon--arrow-shop circle,
.icon.icon--arrow-shop path,
.icon.icon--arrow-shop polygon,
.icon.icon--arrow-shop rect {
  fill: none !important;
}
@media (min-width: 48.0625em) {
  .icon.icon--arrow-shop {
    height: 2.1875rem;
    width: 2.1875rem;
  }
}
@media (min-width: 75.0625em) {
  .icon.icon--arrow-shop {
    height: 2.8125rem;
    width: 2.8125rem;
  }
}
.icon.icon--social {
  height: 0.9375rem;
  width: 0.9375rem;
}
@media (min-width: 64.0625em) {
  .icon.icon--social {
    height: 2.5rem;
    width: 2.5rem;
  }
}

.marquee {
  --gap: 0rem;
  display: flex;
  gap: var(--gap);
  position: relative;
  overflow: hidden;
  user-select: none;
  /* Pause animation when reduced-motion is set */
  /* Enable animation when elements are duplicated */
  /* Pause animation when mouse is hover */
}
.marquee .marquee-inner {
  display: flex;
  flex-shrink: 0;
  gap: var(--gap);
  justify-content: space-around;
  min-width: 100%;
  will-change: transform;
}
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}
@media (prefers-reduced-motion: reduce) {
  .marquee .marquee-inner {
    animation-play-state: paused !important;
  }
}
.marquee.marquee--enable-animation .marquee-inner {
  animation: scroll 45s linear infinite;
}
.marquee.marquee--pause-hover:hover .marquee-inner {
  animation-play-state: paused;
}

.place .wrapper {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  padding-bottom: 1.875rem;
  padding-top: 3.75rem;
}
@media (min-width: 64.0625em) {
  .place .wrapper {
    align-items: center;
    display: flex;
  }
}
.place .place-title {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 2.5rem;
  line-height: 1;
  margin-bottom: 1.5625rem;
}
@media (min-width: 48.0625em) {
  .place .place-title {
    font-size: 3.125rem;
  }
}
@media (min-width: 64.0625em) {
  .place .place-title {
    font-size: 6.25rem;
  }
}
@media (min-width: 64.0625em) {
  .place .place-title {
    margin-bottom: 0;
    width: 30%;
  }
}
@media (min-width: 87.5625em) {
  .place .place-title {
    width: 20%;
  }
}
@media (min-width: 64.0625em) {
  .place .place-list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 1.875rem;
    width: 70%;
  }
}
@media (min-width: 87.5625em) {
  .place .place-list {
    width: 80%;
  }
}
.place .place-info {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
@media (min-width: 48.0625em) {
  .place .place-info {
    margin-left: 1.5%;
    margin-right: 1.5%;
    width: 47%;
  }
}
@media (min-width: 87.5625em) {
  .place .place-info {
    margin-left: 1.5%;
    margin-right: 1.5%;
    width: 30%;
  }
}
.place .place-info:last-of-type {
  margin-right: 0;
}
.place.place--openings .place-title {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 1.875rem;
  line-height: 1.15;
  line-height: 0.8;
}
@media (min-width: 48.0625em) {
  .place.place--openings .place-title {
    font-size: 2.5rem;
  }
}
@media (min-width: 64.0625em) {
  .place.place--openings .place-title {
    font-size: 3.125rem;
  }
}

.scrolling-bar {
  background-color: #F4F0D7;
  border-bottom: 1px solid #000000;
  color: #000000;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}
.scrolling-bar .scrolling-bar-item {
  align-items: center;
  display: inline-flex;
  margin-left: 1.875rem;
}
.scrolling-bar .scrolling-bar-item .label {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.1;
  margin-left: 0.75rem;
  margin-top: 5px;
}
@media (min-width: 64.0625em) {
  .scrolling-bar .scrolling-bar-item .label {
    font-size: 1.25rem;
  }
}

.shop {
  align-items: center;
  border: 1px solid #000000;
  border-radius: 1.25rem;
  color: #000000;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  padding: 0.625rem 1.25rem;
  position: relative;
  text-decoration: none;
}
@media (min-width: 28.125em) {
  .shop {
    border-radius: 5rem;
    padding: 1.25rem 4.375rem;
  }
}
@media (min-width: 48.0625em) {
  .shop {
    margin-bottom: 0;
  }
}
@media (min-width: 64.0625em) {
  .shop {
    bottom: auto;
    margin-left: 0;
    padding: 3.125rem;
  }
}
.shop:visited {
  color: #000000;
}
.shop:hover {
  background-color: #000000;
  border-color: #F4F0D7;
  color: #F4F0D7;
  opacity: 1;
}
.shop:hover svg {
  fill: #F4F0D7;
  stroke: #F4F0D7;
}
.shop:hover svg line,
.shop:hover svg circle,
.shop:hover svg path,
.shop:hover svg polygon,
.shop:hover svg rect {
  fill: #F4F0D7;
  stroke: #F4F0D7;
}
.shop .shop-left-wrapper {
  margin-right: 0;
}
@media (min-width: 28.125em) {
  .shop .shop-left-wrapper {
    margin-right: 0.625rem;
  }
}
.shop .shop-right-wrapper {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
@media (min-width: 28.125em) {
  .shop .shop-right-wrapper {
    flex-shrink: 0;
    position: initial;
  }
}
.shop .shop-title {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 1.5625rem;
  line-height: 1.15;
}
@media (min-width: 28.125em) {
  .shop .shop-title {
    font-size: 1.875rem;
  }
}
@media (min-width: 48.0625em) {
  .shop .shop-title {
    font-size: 2.5rem;
  }
}
@media (min-width: 64.0625em) {
  .shop .shop-title {
    font-size: 3.125rem;
  }
}
.shop .shop-subtitle {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.1;
  margin-bottom: 1.25rem;
}
@media (min-width: 28.125em) {
  .shop .shop-subtitle {
    font-size: 1.5625rem;
  }
}
@media (min-width: 64.0625em) {
  .shop .shop-subtitle {
    font-size: 1.875rem;
  }
}
.shop .shop-description {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 1rem;
  line-height: 1.1;
}
@media (min-width: 28.125em) {
  .shop .shop-description {
    font-size: 1.125rem;
  }
}
@media (min-width: 64.0625em) {
  .shop .shop-description {
    font-size: 1.25rem;
  }
}

.social-buttons {
  list-style: none;
  margin: 0;
  padding-left: 0;
  bottom: 20px;
  right: 10px;
  position: fixed;
  z-index: 1;
}
.social-buttons > li {
  padding: 0;
}
.social-buttons > a {
  display: inline-block;
}
@media (min-width: 48.0625em) {
  .social-buttons {
    bottom: 30px;
    right: 60px;
  }
}
.social-buttons .menu-item {
  display: inline-block;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}
@media (min-width: 48.0625em) {
  .social-buttons .menu-item {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
}
.social-buttons .menu-link {
  align-items: center;
  background-color: #AFAFD5;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  height: 3.625rem;
  width: 3.625rem;
}
@media (min-width: 48.0625em) {
  .social-buttons .menu-link {
    height: 5.625rem;
    width: 5.625rem;
  }
}
.social-buttons .menu-link:hover {
  background-color: #D5B538;
  opacity: 1;
}

.symbol-hero {
  position: absolute;
}

#symbol-hero-circle {
  left: 1%;
  top: 42%;
}
@media (min-width: 87.5625em) {
  #symbol-hero-circle {
    left: 3%;
    top: 42%;
  }
}

#symbol-hero-star {
  left: 30%;
  top: 18%;
}
@media (min-width: 87.5625em) {
  #symbol-hero-star {
    left: 30%;
    top: 20%;
  }
}

#symbol-hero-half-circle {
  right: 10%;
  top: 5%;
}
@media (min-width: 75.0625em) {
  #symbol-hero-half-circle {
    right: 15%;
    top: 10%;
  }
}
@media (min-width: 87.5625em) {
  #symbol-hero-half-circle {
    right: 18%;
    top: 12%;
  }
}

#symbol-hero-four-point {
  right: 6%;
  top: 50%;
}
@media (min-width: 87.5625em) {
  #symbol-hero-four-point {
    right: 2%;
    top: 48%;
  }
}

.theme-link {
  align-items: center;
  border: 1px solid #F4F0D7;
  border-radius: 45px;
  display: inline-flex;
  padding: 1.25rem 1.875rem;
  text-decoration: none;
}
.theme-link:hover {
  background-color: #F4F0D7;
  color: #000000;
  opacity: 1;
}
.theme-link svg {
  display: inline-block;
  margin-right: 0.9375rem;
}
.theme-link .label {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 1.5625rem;
  line-height: 1.1;
  display: block;
  margin-top: 10px;
  white-space: nowrap;
}
@media (min-width: 64.0625em) {
  .theme-link .label {
    font-size: 1.875rem;
  }
}

.theme-text {
  display: inline-flex;
}
.theme-text svg {
  display: inline-block;
  margin-left: 0.625rem;
}
.theme-text.theme--afterhours {
  color: #958746;
}
.theme-text.theme--lounge {
  color: #C5732F;
}
.theme-text.theme--popup {
  color: #D5B538;
}
.theme-text.theme--emporium {
  color: #AFAFD5;
}

footer {
  padding-bottom: 3.125rem;
}
@media (min-width: 48.0625em) {
  footer {
    padding-bottom: 1.875rem;
  }
}
footer .footer-menu {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}

.menu--footer {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.menu--footer > li {
  padding: 0;
}
.menu--footer > a {
  display: inline-block;
}
.menu--footer .menu-item {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.1;
  display: inline-block;
  margin-bottom: 0.625rem;
  margin-right: 1.25rem;
}
@media (min-width: 64.0625em) {
  .menu--footer .menu-item {
    font-size: 1.25rem;
  }
}
@media (min-width: 64.0625em) {
  .menu--footer .menu-item {
    margin-right: 10%;
  }
}
.menu--footer .menu-link {
  color: #F4F0D7;
}

.section--basic-page {
  background-color: #F4F0D7;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  color: #000000;
  margin-bottom: 3.125rem;
  min-height: calc(100vh - 71px);
  padding-bottom: 3.75rem;
  overflow: hidden;
}
.section--basic-page .wrapper {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  margin-top: 3.75rem;
}
.section--basic-page .intro-logo {
  margin-bottom: 6.25rem;
}
.section--basic-page .page-title {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 2.5rem;
  line-height: 1;
  margin-bottom: 1.875rem;
}
@media (min-width: 48.0625em) {
  .section--basic-page .page-title {
    font-size: 3.125rem;
  }
}
@media (min-width: 64.0625em) {
  .section--basic-page .page-title {
    font-size: 6.25rem;
  }
}
.section--basic-page h2 {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.1;
  font-weight: 700;
  margin-bottom: 0.625rem;
}
@media (min-width: 64.0625em) {
  .section--basic-page h2 {
    font-size: 1.25rem;
  }
}
.section--basic-page p {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.1;
  margin-bottom: 3.125rem;
}
@media (min-width: 64.0625em) {
  .section--basic-page p {
    font-size: 1.25rem;
  }
}

.section--hero {
  background-color: #F4F0D7;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  height: calc(100vh - 71px);
  overflow: hidden;
  position: relative;
}
.section--hero .wrapper {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 3.75rem;
  padding-top: 3.75rem;
  position: relative;
  z-index: 1;
}
.section--hero .title {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 2.5rem;
  line-height: 1;
  color: #000000;
  cursor: pointer;
  margin-bottom: 1.25rem;
  margin-top: auto;
  transition: transform 150ms ease-in-out;
  user-select: none;
}
@media (min-width: 28.125em) {
  .section--hero .title {
    font-size: 3.125rem;
  }
}
@media (min-width: 48.0625em) {
  .section--hero .title {
    font-size: 5rem;
  }
}
@media (min-width: 64.0625em) {
  .section--hero .title {
    font-size: 8.125rem;
  }
}
.section--hero .title:hover {
  transform: translateX(10px) !important;
}

.section--intro {
  border-bottom: 1px solid #F4F0D7;
  padding-bottom: 6.25rem;
  padding-top: 6.25rem;
}
.section--intro .wrapper {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}
.section--intro .text {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 1.875rem;
  line-height: 1.15;
}
@media (min-width: 48.0625em) {
  .section--intro .text {
    font-size: 2.5rem;
  }
}
@media (min-width: 64.0625em) {
  .section--intro .text {
    font-size: 3.125rem;
  }
}

.section--places {
  padding-bottom: 3.75rem;
}
.section--places .item {
  border-bottom: 1px solid #F4F0D7;
}

.section--shops {
  background-color: #F4F0D7;
  border-radius: 100px;
  margin-bottom: 3.125rem;
  position: relative;
}
.section--shops .wrapper {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}
.section--shops .info-wrapper {
  padding-bottom: 2.5rem;
  padding-left: 1.25rem;
  padding-top: 3.75rem;
}
@media (min-width: 64.0625em) {
  .section--shops .info-wrapper {
    padding-bottom: 4.8125rem;
    padding-top: 5rem;
    width: 70%;
  }
}
.section--shops .info-wrapper .title {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 2.5rem;
  line-height: 1;
  color: #000000;
  margin-bottom: 1.25rem;
}
@media (min-width: 48.0625em) {
  .section--shops .info-wrapper .title {
    font-size: 3.125rem;
  }
}
@media (min-width: 64.0625em) {
  .section--shops .info-wrapper .title {
    font-size: 6.25rem;
  }
}
@media (min-width: 64.0625em) {
  .section--shops .info-wrapper .title {
    margin-bottom: 2.5rem;
  }
}
.section--shops .info-wrapper .description {
  font-weight: 300;
  letter-spacing: 0;
  margin: 0;
  font-size: 1.5625rem;
  line-height: 1.1;
  color: #000000;
  margin-bottom: 1.25rem;
}
@media (min-width: 64.0625em) {
  .section--shops .info-wrapper .description {
    font-size: 1.875rem;
  }
}
@media (min-width: 64.0625em) {
  .section--shops .info-wrapper .description {
    margin-bottom: 0;
  }
}
.section--shops .shop-wrapper {
  padding-bottom: 3.75rem;
}
@media (min-width: 48.0625em) {
  .section--shops .shop-wrapper {
    column-gap: 1.875rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 6.25rem;
    row-gap: 1.875rem;
  }
}