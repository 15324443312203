.section--basic-page {
  background-color: $color-yellow-light;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  color: $color-black;
  margin-bottom: pxrem(50);
  min-height: calc(100vh - #{$header-height});
  padding-bottom: pxrem(60);
  overflow: hidden;

  .wrapper {
    @include max-width;
    margin-top: pxrem(60);
  }

  .intro-logo {
    margin-bottom: pxrem(100);
  }

  .page-title {
    @include typo-1-small;
    margin-bottom: pxrem(30);
  }

  h2 {
    @include typo-4;
    font-weight: 700;
    margin-bottom: pxrem(10);
  }

  p {
    @include typo-4;
    margin-bottom: pxrem(50);
  }
}