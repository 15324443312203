* {
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: 0;
}

html {
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
	
  width: 100%;
}

body {
  @include font-smoothing;
  background-color: $color-black;
  color: $color-yellow-light;
  -webkit-text-size-adjust: 100%;
  font-family: $font-primary;
  font-size: $font-normal;
  height: 100%;
  line-height: 1.2;
  min-height: 100%;
  width: 100%;
}

[unselectable] {
	-webkit-touch-callout: none;
	user-select: none;
}

a {
	color: $color-yellow-light;
	opacity: 1;
	transition: all $transition-default;

	&:hover {
		opacity: .75;
	}

	&:visited,
	&:active {
		color: $color-yellow-light;
	}
}

img, media, svg {
  display: block;
  height: auto;
  max-width: 100%;
}

.svg-symbols {
  height: 0;
}

ul, ol {
  margin: 0;
}

/* Chrome autocomplete form fix */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

/* IE10+ specific styles */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    height: auto;
  }
}

/* Safari 9 specific styles */
@supports (overflow:-webkit-marquee)
   and (justify-content:inherit) {
    html {
      height: auto;
    }
}