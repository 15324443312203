.menu--footer {
  @include list-reset;

  .menu-item {
    @include typo-4;
    display: inline-block;
    margin-bottom: pxrem(10);
    margin-right: pxrem(20);

    @include mq(lg) {
      margin-right: 10%;
    }
  }

  .menu-link {
    color: $color-yellow-light;
  }
}