.marquee {
  --gap: 0rem;
  display: flex;
  gap: var(--gap);
  position: relative;
  overflow: hidden;
  user-select: none;

  .marquee-inner {
    display: flex;
    flex-shrink: 0;
    gap: var(--gap);
    justify-content: space-around;
    min-width: 100%;
    will-change: transform;
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }

  /* Pause animation when reduced-motion is set */
  @media (prefers-reduced-motion: reduce) {
    .marquee-inner {
      animation-play-state: paused !important;
    }
  }

  /* Enable animation when elements are duplicated */
  &.marquee--enable-animation .marquee-inner {
    animation: scroll 45s linear infinite;
  }

  /* Pause animation when mouse is hover */
  &.marquee--pause-hover:hover .marquee-inner {
    animation-play-state: paused;
  }

  
}
