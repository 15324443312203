footer {
  padding-bottom: pxrem(50);

  @include mq(md) {
    padding-bottom: pxrem(30);
  }

  .footer-menu {
    @include max-width;
  }
}