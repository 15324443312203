.symbol-hero {
  position: absolute;
}

#symbol-hero-circle {
  left: 1%;
  top: 42%;

  @include mq(xxl) {
    left: 3%;
    top: 42%;
  }
}

#symbol-hero-star {
  left: 30%;
  top: 18%;

  @include mq(xxl) {
    left: 30%;
    top: 20%;
  }
}

#symbol-hero-half-circle {
  right: 10%;
  top: 5%;

  @include mq(xl) {
    right: 15%;
    top: 10%;
  }

  @include mq(xxl) {
    right: 18%;
    top: 12%;
  }
}

#symbol-hero-four-point {
  right: 6%;
  top: 50%;

  @include mq(xxl) {
    right: 2%;
    top: 48%;
  }
}