.shop {
  align-items: center;
  border: 1px solid $color-black;
  border-radius: pxrem(20);
  color: $color-black;
  display: flex;
  justify-content: space-between;
  margin-bottom: pxrem(10);
  padding: pxrem(10) pxrem(20);
  position: relative;
  text-decoration: none;

  @include mq(xs) {
    border-radius: pxrem(80);
    padding: pxrem(20) pxrem(70);
  }

  @include mq(md) {
    margin-bottom: 0;
  }

  @include mq(lg) {
    bottom: auto;
    margin-left: 0;
    padding: pxrem(50);
  }

  &:visited {
    color: $color-black;
  }

  &:hover {
    background-color: $color-black;
    border-color: $color-yellow-light;
    color: $color-yellow-light;
    opacity: 1;

    svg {
      @include icon-color($color-yellow-light);
    }
  }

  .shop-left-wrapper {
    margin-right: 0;

    @include mq(xs) {
      margin-right: pxrem(10);
    }
  }

  .shop-right-wrapper {
    position: absolute;
    right: 1rem;
    top: 1rem;

    @include mq(xs) {
      flex-shrink: 0;
      position: initial;
    }
  }


  .shop-title {
    @include typo-2-scale;
  }

  .shop-subtitle {
    @include typo-3-scale;
    margin-bottom: pxrem(20);
  }

  .shop-description {
    @include typo-4-scale;
  }
}